@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?w6sjgt');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?w6sjgt#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?w6sjgt') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?w6sjgt') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?w6sjgt##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-telegram1 {
  &:before {
    content: $icon-telegram1; 
  }
}
.icon-bitcoin {
  &:before {
    content: $icon-bitcoin; 
  }
}
.icon-btc {
  &:before {
    content: $icon-btc; 
  }
}
.icon-eur {
  &:before {
    content: $icon-eur; 
  }
}
.icon-euro {
  &:before {
    content: $icon-euro; 
  }
}
.icon-bank {
  &:before {
    content: $icon-bank; 
  }
}
.icon-institution {
  &:before {
    content: $icon-institution; 
  }
}
.icon-university {
  &:before {
    content: $icon-university; 
  }
}
.icon-paypal {
  &:before {
    content: $icon-paypal; 
  }
}
.icon-soundcloud {
  &:before {
    content: $icon-soundcloud; 
  }
}

