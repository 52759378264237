$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

$icon-telegram1: "\e911";
$icon-bitcoin: "\e900";
$icon-btc: "\e900";
$icon-eur: "\e901";
$icon-euro: "\e901";
$icon-bank: "\e902";
$icon-institution: "\e902";
$icon-university: "\e902";
$icon-paypal: "\e90d";
$icon-soundcloud: "\eac3";